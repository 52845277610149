.pagination {
  display: flex;
  font: 12px 'Graphik LC', sans-serif;
  list-style-type: none;
  padding-bottom: 65px;
}

.pagination li {
  color: #0036ff;
  cursor: pointer;
  padding-right: 30px;
}

.pagination svg {
  fill: #0036ff;
}

.pagination .disabled {
  color: #333;
}

.disabled svg {
  fill: #333;
}

.pagination .active {
  color: #333;
}
