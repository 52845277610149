@font-face {
  font-family: 'Graphik LC';
  src: url('./font/Graphik-Bold-Cy-Web.eot?#iefix') format('embedded-opentype'),
    url('./font/Graphik-Bold-Cy-Web.woff') format('woff'),
    url('./font/Graphik-Bold-Cy-Web.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik LC';
  src: url('./font/Graphik-Regular-Cy-Web.eot?#iefix')
      format('embedded-opentype'),
    url('./font/Graphik-Regular-Cy-Web.woff') format('woff'),
    url('./font/Graphik-Regular-Cy-Web.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Fira Mono';
  src: url('./font/firamono/firamono-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./font/firamono/firamono-regular-webfont.woff2') format('woff2'),
    url('./font/firamono/firamono-regular-webfont.woff') format('woff'),
    url('./font/firamono/firamono-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Mono';
  src: url('./font/firamono/firamono-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./font/firamono/firamono-bold-webfont.woff2') format('woff2'),
    url('./font/firamono/firamono-bold-webfont.woff') format('woff'),
    url('./font/firamono/firamono-bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'William Text';
  src: url('./font/williamtext/WilliamTextStd-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./font/williamtext/WilliamTextStd-Regular.woff') format('woff'),
    url('./font/williamtext/WilliamTextStd-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'William Text';
  src: url('./font/williamtext/WilliamTextStd-RegularItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./font/williamtext/WilliamTextStd-RegularItalic.woff') format('woff'),
    url('./font/williamtext/WilliamTextStd-RegularItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

html {
  height: 100%;
}

body {
  background: #f7f7f7;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
input {
  box-sizing: border-box;
}
#root {
  height: 100%;
}
